<template>
    <div>
        <ul class="nav flex">
            <li @click="nav_tab(item.id)" :class="nav_id == item.id ? 'active' : ''" v-for="item in nav_list"
                :key="item.id">{{ item.title }}
                <el-badge :value="item[num]" class="item" v-if="item[num]" :max="99">
                </el-badge>
                <!-- <span class="status_num" v-if="item[num]">{{ item[num] }}</span> -->
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        nav_id: Number,
        num: String,
    },
    mounted() {
    },
    data() {
        return {
            nav_list: [],

        }
    },
    methods: {
        nav_tab(id) {
            this.$emit('nav_tab', id)
            this.getMediaNav()
        },
        getMediaNav() {
            this.curlGet('/api/medium_category/list').then(res => {
                if (res.data.code) {
                    this.nav_list = res.data.data
                }
            })
        },

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/order_nav'
</style>